const googleMapConfig = {
  googleMap: {
    marker: {
      icon: {
        url: 'https://res.cloudinary.com/difp9jq5j/image/upload/c_thumb,h_37/v1679066924/themes/pkag/images/icons/map-marker.png',
      },
    },
    polyline: {
      strokeColor: '#eab945',
    },
  },
}

export default googleMapConfig
