const travelFilterConfig = {
  travelFilter: {
    inputs: {
      destinations: {
        enabled: true,
      },
      months: {
        enabled: true,
      },
      travelTypes: {
        enabled: false,
      },
      query: {
        enabled: false,
      },
    },
  },
}

export default travelFilterConfig
